<template>
  <div
    :id="ids.main"
    v-click-outside="event => handleShow(event, false)"
    class="popover-wrapper inline cursor-pointer"
  >
    <div
      ref="main"
      class="popover-main flex"
      :class="popoverMainClass"
      @click="event => handleShow(event)"
      @mouseover="event => handleShow(event, true)"
      @mouseout="event => handleShow(event, false)"
    >
      <slot />
    </div>

    <div
      v-show="false"
      :id="ids.content"
      ref="contentRef"
      class="popover-popup absolute z-[99]"
      :class="innerClass"
      :style="{
        maxWidth: component.popoverWidth
      }"
    >
      <atoms-tooltip
        :append-popover-class="appendPopoverClass"
        :tip-x="tipX"
        :tip-y="tipY"
        :type="theme"
        :radius="radius"
        :tip-x-adjustment="tipAdjustment.x"
        :tip-in-side="['left', 'right'].includes(tip)"
        :has-close="hasClose"
        :small-close="smallClose"
        :has-icon="false"
        always-show
        @close="handleShow({ type: 'click' }, false)"
      >
        <template #title>
          <slot name="title" />
        </template>

        <slot name="content" />
      </atoms-tooltip>
    </div>
  </div>
</template>

<script setup>
import {
  generateUID,
  getElOffset,
  getKey
} from '~/helpers/functions.js'

defineOptions({
  name: 'AtomsPopoverV2'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'dark', 'purple', 'info'].includes(value)
  },

  position: {
    type: String,
    default: 'bottom',
    validator: value => ['top', 'bottom', 'left', 'right'].includes(value)
  },

  hasClose: {
    type: Boolean,
    default: false
  },

  smallClose: {
    type: Boolean,
    default: false
  },

  hasShadow: {
    type: Boolean,
    default: false
  },

  actionType: {
    type: String,
    default: 'hover',
    validator: value => ['click', 'hover'].includes(value)
  },

  popoverMargin: {
    type: Number,
    default: 6
  },

  popoverWidth: {
    type: [String, Number],
    default: '288px'
  },

  /**
   * @params {String}
   * @value 2md, md, lg
   */
  radius: {
    type: String,
    default: ''
  },

  popoverMainClass: {
    type: String,
    default: ''
  },

  appendPopoverClass: {
    type: String,
    default: ''
  },

  innerClass: {
    type: String,
    default: ''
  },

  positionLeftAdjustment: {
    type: Number,
    default: 0
  }
})

const content = ref({})
const contentRef = ref()
const status = ref(false)
const tip = ref('')
const tipAdjustment = ref({
  x: '0',
  y: '0'
})
const main = ref(null)

const component = computed(() => {
  const popoverWidth = `${props.popoverWidth}`.replace('px', '')

  return {
    popoverWidth: popoverWidth + (popoverWidth === 'auto' ? '' : 'px')
  }
})

const ids = computed(() => {
  return {
    main: generateUID('popover'),
    content: generateUID('popover-content')
  }
})

const tipY = computed(() => {
  if (['top', 'bottom'].includes(tip.value)) {
    return tip.value === 'bottom' ? 'top' : 'bottom'
  }

  if (['left', 'right'].includes(tip.value)) {
    return 'middle'
  }
  return 'middle'
})

const tipX = computed(() => {
  if (['left', 'right'].includes(tip.value)) {
    return tip.value === 'left' ? 'right' : 'left'
  }

  return 'center'
})

onMounted(async () => {
  await nextTick()

  if (contentRef.value) {
    window.document.body.appendChild(contentRef.value)

    const cloneContent = contentRef.value.cloneNode(true)
    cloneContent.style.display = 'block'
    window.document.body.appendChild(cloneContent)

    content.value = cloneContent
    cloneContent.remove()

    window.addEventListener('resize', calculatePosition)
    window.addEventListener('scroll', calculatePosition)
  }
})

onBeforeUnmount(() => {
  const contentVal = contentRef.value

  if (contentVal) {
    contentVal.remove()
  }

  window.removeEventListener('resize', calculatePosition)
  window.removeEventListener('scroll', calculatePosition)
})

const handleShow = async ($event, show = null) => {
  await wait(300)
  const eventType = $event?.type

  const actionMap = getKey(eventType, {
    click: 'click',
    mouseover: 'hover',
    mouseout: 'hover'
  })

  if (props.actionType !== actionMap) {
    return
  }

  if (show === null) {
    status.value = !status.value
  } else {
    status.value = show
  }

  const contentEl = contentRef.value

  if (contentEl) {
    contentEl.style.display = status.value ? 'block' : 'none'

    if (status.value) {
      calculatePosition()
    } else {
      contentEl.style.top = ''
      contentEl.style.left = ''
    }

    if (status.value) {
      contentEl.classList.add('popover-show')
    } else if (contentEl.classList.contains('popover-show')) {
      contentEl.classList.remove('popover-show')
    }
  }
  await nextTick()
}

const calculatePosition = () => {
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth)
  const contentEl = contentRef.value

  const mainRect = main.value?.getBoundingClientRect()
  const contentRect = content.value?.getBoundingClientRect()
  const margin = props.popoverMargin

  const viewport = (() => {
    return {
      top: (mainRect.top - contentRect.height - margin) >= 0,
      left: (mainRect.left - contentRect.width - margin) >= 0,
      bottom: (mainRect.bottom + contentRect.height + margin) <= (window.innerHeight || document.documentElement.clientHeight),
      right: (mainRect.right + contentRect.width + margin) <= document.documentElement.clientWidth
    }
  })()

  const position = getKey(props.position, {
    bottom: viewport.bottom ? 'bottom' : 'top',
    top: viewport.top ? 'top' : 'bottom',
    right: (() => {
      if (!viewport.left && !viewport.right) {
        return viewport.bottom ? 'bottom' : 'top'
      }

      return viewport.right ? 'right' : 'left'
    })(),
    left: (() => {
      if (!viewport.left || !viewport.right) {
        return viewport.bottom ? 'bottom' : 'top'
      }

      return viewport.left ? 'left' : 'right'
    })()
  })

  tip.value = position

  const offsetTop = getElOffset(main.value).top
  const offsetLeft = getElOffset(main.value).left

  const newPos = `${getKey(position, {
    bottom: offsetTop + main.value.clientHeight + margin,
    top: offsetTop - contentEl.clientHeight - margin,
    right: offsetLeft + main.value.clientWidth + margin,
    left: offsetLeft - contentEl.clientWidth - margin - props.positionLeftAdjustment
  })}px`

  const newLeft = (() => {
    const middle = (contentEl.clientWidth - main.value.clientWidth) / 2
    let temp = offsetLeft - middle

    const negativeLeft = temp < 0
    const inViewport = (pos = temp) => (contentEl.clientWidth + pos) < windowWidth

    const adjustPos = !(inViewport() && !negativeLeft)
    const outsideViewportPx = (contentEl.clientWidth + offsetLeft) - windowWidth
    const gapFromLeft = windowWidth - (main.value.clientWidth + offsetLeft)

    const tipWidth = 14
    const extraSpace = 20

    if (adjustPos) {
      if (negativeLeft) {
        temp = mainRect.left - (mainRect.left / 2)

        if (!inViewport(temp)) {
          temp = Math.abs(offsetLeft - outsideViewportPx) - extraSpace
        }
      }

      if (!inViewport()) {
        const tempLeft = mainRect.left - contentEl.clientWidth + main.value.clientWidth
        temp = Math.abs(offsetLeft - outsideViewportPx) - (gapFromLeft / 2)

        if (temp < 0) {
          temp = tempLeft + gapFromLeft - extraSpace
        }
      }

      // moves the tip to the middle
      tipAdjustment.value.x = `${Math.abs(mainRect.left - temp) + ((main.value.clientWidth - tipWidth) / 2) - 5}px`
    } else {
      tipAdjustment.value.x = ''
    }

    return `${temp}px`
  })()

  if (['bottom', 'top'].includes(position)) {
    contentEl.style.left = newLeft
    contentEl.style.top = newPos
  } else if (['left', 'right'].includes(position)) {
    contentEl.style.top = `${offsetTop - ((contentEl.clientHeight - main.value.clientHeight) / 2)}px`
    contentEl.style.left = newPos
  }
}

</script>
